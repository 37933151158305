import { Controller } from '@hotwired/stimulus';
import { computePosition, offset, flip, shift } from '@floating-ui/dom';
import type { Placement, Middleware } from '@floating-ui/dom';

// StimulusJS controller to handle the dropdown component.
export default class PopupController extends Controller {
  // There is a slight delay between hovering over the button and the dropdown
  // opening. This is to prevent the dropdown from opening when the user is
  // just passing over the button on their way to something else.
  OPEN_DELAY = 50;
  // There is a slight delay between hovering out of the dropdown and the
  // dropdown closing. This is to prevent the dropdown from closing when the
  // user is moving their mouse from the button to the dropdown.
  CLOSE_DELAY = 50;
  // The options passed to the computePosition function.
  POSITION_OPTIONS: {
    placement: Placement,
    middleware: Middleware[]
  } = {
      placement: 'bottom-start', // Default to bottom-start
      middleware: [offset(6), flip(), shift({ padding: 5 })]
    };

  // Holding a reference to the timer allows us to cancel clear it if the user
  // hovers back over the button before the dropdown has closed.
  timer: NodeJS.Timeout | undefined;

  static targets = ["button", "popup"];
  declare popupTarget: HTMLDivElement;
  declare buttonTarget: HTMLLinkElement;

  static classes = ["hover"];
  declare hoverClasses: string[];

  static values = { position: String };
  declare positionValue: Placement;

  // The #update method is calculates the correct position for the dropdown
  // and is called when the dropdown is opened.
  async update() : Promise<void> {
    const options = this.POSITION_OPTIONS;
    options.placement = this.positionValue;
    const button = this.buttonTarget;
    const popup = this.popupTarget;
    const {x, y} = await computePosition(button, popup, options);
    Object.assign(popup.style, { left: `${x}px`, top: `${y}px` });
  }

  // Open the dropdown when the user hovers over the button.
  open(): void {
    clearTimeout(this.timer);
    setTimeout(
      () => {
        // Transition the button's styling to the hover state
        this.buttonTarget.classList.add(...this.hoverClasses);
        // Reveal the dropdown
        this.popupTarget.style.display = 'block';
        // Position the dropdown
        void this.update();
      },
      this.OPEN_DELAY
    );
  }

  // There is a slight delay between hovering out of the dropdown and
  // the dropdown closing. This is to prevent the dropdown from closing
  // when the user is moving their mouse from the button to the dropdown.
  close() : void {
    this.timer = setTimeout(
      this.closeImmediately.bind(this),
      this.CLOSE_DELAY
    );
  }

  // We also expose a way to close the dropdown immediately. This is used
  // when the user hovers on another dropdown button.
  closeImmediately() : void {
    // Transition the button's styling from the hover state
    this.buttonTarget.classList.remove(...this.hoverClasses);
    // Remove the dropdown
    this.popupTarget.style.display = 'none';
  }
}
