import { Controller } from '@hotwired/stimulus';

// Controller that is used to open/reveal a modal.
export default class ModalRevealController extends Controller<HTMLElement> {
  static outlets = ['modal'];
  declare modalOutlets: Controller<HTMLElement>[];

  call(): void {
    const controllerId = this.element.dataset.modalId;
    const correctModal = this.modalOutlets.find((outlet) => {
      return outlet.element.dataset.modalId === controllerId;
    });

    if (correctModal == null) {
      throw new Error(`Could not find modal element with id ${controllerId}`);
    }

    correctModal.element.classList.remove('hidden');
  }
}
