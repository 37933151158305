import { Controller } from "@hotwired/stimulus";

export default class ButtonController extends Controller<HTMLFormElement>{
  static targets = ['button'];
  static values = { text: String };

  declare buttonTarget: HTMLButtonElement;
  declare textValue: string;

  disable(): void {
    this.buttonTarget.disabled = true;
    this.buttonTarget.innerText = this.textValue;
  }
}
